<template>
  <div
    ref="rightList"
    class="rightContainer"
    @scroll="ws_rightListScrollAction"
  >
    <div
      class="rightListItem"
      v-for="item in rightListArray"
      :key="item.goodsId"
      @click="ws_pushMeaDetailAction(item)"
    >
      <div class="ImgView">
        <img :src="item.mainPic" :mode="imageMode" />
      </div>
      <div class="infoView">
        <div class="infoTitle ws_twoLine">{{ item.paperName }}</div>
        <div class="infoRemark ws_twoLine">{{ item.goodsDesc }}</div>
        <!-- <div class="infoPrice ws_oneLine">￥{{ item.price }}</div> -->
      </div>
      <div class="btnGo">发送量表</div>
    </div>
    <!-- 占位view -->
    <div style="height: 20px;"></div>
    <img
      v-if="isEmpty"
      class="emptyImg"
      src="../../../assets/images/base_empty.png"
      :mode="imageMode"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageMode: "scaleToFill",
      listObj: undefined,
      platformType: "1", // 1:iOS  2:android
      //   emptyImg: require('@/assets/images/base_empty.png')   //  动态引入图片
    };
  },
  props: {
    rightListArray: {
      typeof: Array,
      default: [],
    },
    isEmpty:{
      typeof: Boolean,
      default:false
    }
  },
  mounted() {
    this.platformType = this.$route.query.platformType;
    this.listObj = this.$refs.rightList;
  },
  methods: {
    ws_pushMeaDetailAction(item) {
      // console.log("参数===" + this.platformType)
      //1:iOS  2:android
      if (this.platformType == "1") {
        window.webkit.messageHandlers.getScaleObjAction.postMessage(item);
      } else if (this.platformType == "2") {
        const str = JSON.stringify(item);
        window.android.getScaleObjAction(str);
      } else {
        // uni.showToast({
        // 	title:`参数platformType:${this.platformType}异常`,
        // 	icon: 'none'
        // });
        alert("参数platformType:${this.platformType}异常");
      }
    },
    //监听页面滚动
    ws_rightListScrollAction() {
      // console.log(e)
      // console.log('clientHeight===' + this.listObj.clientHeight + "; scrollTop==" + this.listObj.scrollTop + "; scrollHeight===" + this.listObj.scrollHeight);

      if (
        this.listObj.clientHeight + this.listObj.scrollTop ===
        this.listObj.scrollHeight
      ) {
        this.$emit("toBottomaAction");
        // console.log("1111")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/variables.scss";
.rightContainer {
  // flex: 1;
  // position: absolute;
  // left: 1.5rem;
  // top: 0;
  // right: 0;
  // height: 100%;
  // overflow: scroll;
  // padding-top: .2rem;
  // background-color: #fafafa;
  .emptyImg {
    position: absolute;
    width: 187.5px;
    height: 201.5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .listView {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: red;
  }
  .rightListItem {
    /* border: 1rpx solid red; */
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.2rem;
    height: 1.5rem;
    margin-bottom: 0.3rem;
    display: flex;
    position: relative;
    .ImgView {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.2rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.1rem;
      }
    }
    // 600 - 20*2 - 150 - 410
    .infoView {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      justify-content: space-around;
      .infoTitle {
        width: 100%;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .infoRemark {
        width: 2.7rem;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .infoPrice {
        width: 2.7rem;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ff5252;
      }
    }
    .btnGo {
      width: 1.2rem;
      height: 0.5rem;
      background-color: $ws-mainColor;
      border-radius: 0.25rem;
      position: absolute;
      right: 0.2rem;
      bottom: 0;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.5rem;
      text-align: center;
    }
  }
}
</style>
