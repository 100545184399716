<template>
    <div class="leftContainer">
        <div
				class="itemMenu"
				:style="{ backgroundColor: wsCurrentCategoryId == item.classifyID ? '#fafafa' : '#E6E6E6' }"
				v-for="item in categoryArray"
				:key="item.classifyID"
				@click.stop="ws_leftListItemClickAction(item)"
			>
				{{ item.classifyName }}
				<div class="titleBar" v-if="wsCurrentCategoryId == item.classifyID"></div>
		</div>
		<!-- 占位view-->
		<div class="placeholder"></div>
    </div>
</template>

<script>
export default {
    name:'LeftList',
    data() {
        return {
            wsCurrentCategoryId:"1"
        }
    },
    props:{
       currentClassifyId: {
           default: "1",
           typeof:String

       },
       categoryArray:Array
    },
    mounted(){
        this.wsCurrentCategoryId = this.currentClassifyId
    },
    methods:{
		placeholderClickAction(e){
			e.stopPropagation();
		},
        //左侧列表点击事件
        ws_leftListItemClickAction(item){
            // console.log(item);
            this.wsCurrentCategoryId = item.classifyID
            this.$emit('getClassifyAction',item.classifyID)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~styles/variables.scss';
//在样式里面引入样式import前面必须加@符号 assets
//在样式里面引入样式时候使用别名的话需要在前面加上波浪线~
//@import '../../../assets/styles/varibles.styl';//或者@import '~@/assets/styles/varibles.styl'
//使用别名替换
// @import '~styles/varibles.styl'
    .leftContainer {
		// position: fixed;
		// top: 0;
		// left: 0;
		// width: 1.5rem;
		// height: 100vh;
		// // height: 100%;
		// background-color: #e6e6e6;
		height: 100%;
        width: 100%;
		display: flex;
		flex-direction: column;
		.itemMenu {
			width: 100%;
			height: 1.4rem;
			text-align: center;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 1.4rem;
			position: relative;
			.titleBar {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
                width: 0.1rem;
                background-color: $ws-mainColor;
				height: 0.5rem;
				border-radius: 0.05rem;
				overflow: hidden;
			}
		}
		.placeholder{
			width: 100%;
			flex: 1;
			// background-color: red;
		}
	}
</style>

