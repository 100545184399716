import Vue from 'vue'
import VueRouter from 'vue-router'
import CategoryList from '@/pages/categoryList/categoryList.vue' //@表示src文件路径

Vue.use(VueRouter)

/**
 * 注意:app.js加载的时候至少超过1mb的时候才会使用异步加载组件的方式,不然反而会降低项目的性能
 * 
 * 一般情况下都是使用同步加载组件的方式
 * 
 * 
 * 
 */
 const routes = [{
    path: '/glist',
    name: 'CategoryList',
    component: CategoryList //这中引入方式是同步加载组件方式 加载第一个页面的时候app.js会把所有的页面js都加载完 再进入其他页面js不会再次加载
    // component: ()=>import('@/pages/categoryList/categoryList.vue')//异步加载组件: 每次进入不同的页面加载自己单独的js文件
  },{
    path: '/bp',
    name: 'yhaxbp',
    component: resolve => require(['@/pages/yhax/buyprivacy'], resolve)
  }, {
    path: '/privacy',
    name: 'yhaxprivacy',
    component: resolve => require(['@/pages/yhax/privacy'], resolve)
  }, {
    path: '/know',
    name: 'yhaxknow',
    component: resolve => require(['@/pages/yhax/know'], resolve)
  }, {
    path: '/user',
    name: 'yhaxuser',
    component: resolve => require(['@/pages/yhax/user'], resolve)
  }
  // ,
  // {
  //   path: '/',
  //   name: 'CategoryList',
  //   component: CategoryList //这中引入方式是同步加载组件方式 加载第一个页面的时候app.js会把所有的页面js都加载完 再进入其他页面js不会再次加载
  //   // component: ()=>import('@/pages/categoryList/categoryList.vue')//异步加载组件: 每次进入不同的页面加载自己单独的js文件
  // }
//   ,
//   {
//     path: '/detail/:id',
//     name: 'detail',
//     component: Detail
//     // component: ()=>import('@/pages/detail/Detail.vue')//异步加载组件
//   }

];
const router = new VueRouter({
  routes,
  // 让页面切换的时候始终在最顶部
  //  scrollBehavior(to, from, savedPosition){
  //     return {x:0,y:0}
  //  }
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
