import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import fastClick from 'fastclick'//解决移动端3点击屏幕00ms延迟
// import VConsole from 'vconsole'
// Vue.use(new VConsole())

//使用文件别名引入
import 'styles/reset.css'//import './assets/styles/reset.css'
//自定义公共类
import 'styles/common.css'

Vue.config.productionTip = false

fastClick.attach(document.body)//解决移动端点击屏幕00ms延迟

// Vue.prototype.kHost = "https://test.aiyumi.mirrorego.com/";
// Vue.prototype.kHost = "https://student.aiyumi.mirrorego.com/";
// Vue.prototype.kHost = "https://szu.aiyumi.yanghejx.com/";
// Vue.prototype.kHost = "https://aiyumi.yanghejx.com/";

Vue.prototype.kHost = 'https://applet-test.yanghejx.com/gateway/counselor/api/';//saas二期
// Vue.prototype.kHost = '/api'
Vue.prototype.kVersion = "1.0.2";
Vue.prototype.kPlatformId = '2'

new Vue({
  router:Router,
  render: h => h(App),
}).$mount('#app')
