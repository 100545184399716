<template>
  <div class="container">
    <left-list
      class="leftContainer"
      :categoryArray="categoryArray"
      @getClassifyAction="getCurrentClassifyFromLeftListAction"
    ></left-list>
    <right-list
      class="rightContainer"
      :rightListArray="rightListArray" :isEmpty="isEmpty"
      @toBottomaAction="ws_onReachBottomAction"
    ></right-list>
  </div>
</template>

<script>
import LeftList from "./components/leftList";
import RightList from "./components/rightList";
import axios from "axios";
export default {
  name: "CategoryList",
  data() {
    return {
      categoryArray: [], //左侧类别列表数据
      currentClassifyId: 1, //当前选择的类别 currentMenu
      rightListArray: [], //测评数据
      imageMode: "scaleToFill",
      // safeAreaHeight:uni.getStorageSync('deviceInfo').safeAreaHeight + 20,//安全局域高度,
      isRefreshData: false, //是否下拉刷新数据,防止下拉操作重复请求数据
      isCanLoadMoreData: true, //是否在上拉更多数据,防止上拉操作重复请求数据
      page: 1, //页码
      pageSize: 20, //每页数据源个数

      // platformType:'1',// 1:iOS  2:android
      isNetWork: false, //是否正在网络请求
	    isEmpty: false
    };
  },
  components: {
    LeftList, //同步加载组件
    RightList,
  },
  mounted() {
	this.isEmpty = false;
    // this.platformType = this.$route.query.platformType
    this.ws_getCategoryListNetWrok(); //左侧列表网络请求
    this.ws_getListNetWorkAction(); //右侧列表网络请求
  },

  methods: {
    // ws_pushMeaDetailAction(item) {
    // 	// console.log("参数===" + this.platformType)
    // 	//1:iOS  2:android
    // 	if(this.platformType == "1"){
    // 		window.webkit.messageHandlers.getScaleObjAction.postMessage(item);
    // 	}else if(this.platformType == "2"){
    // 		const str = JSON.stringify(item)
    // 		window.android.getScaleObjAction(str);
    // 	}else{
    // 		// uni.showToast({
    // 		// 	title:`参数platformType:${this.platformType}异常`,
    // 		// 	icon: 'none'
    // 		// });
    //         alert('参数platformType:${this.platformType}异常')
    // 	}
    // },
    //获取gClassify
    getCurrentClassifyFromLeftListAction(classifyID) {
      if (this.isNetWork) {
        return;
      }
      this.currentClassifyId = classifyID;
      this.isNetWork = true;
      // this.rightListArray = [];
      this.isCanLoadMoreData = true;
      this.page = 1;
      this.ws_getListNetWorkAction(); //右侧列表网络请求
    },
    //上拉加载更多
    ws_onReachBottomAction() {
      if (this.isNetWork) {
        return;
      }
      if (this.isCanLoadMoreData) {
        this.page += 1;
        this.isNetWork = true;
        this.ws_getListNetWorkAction(); //右侧列表网络请求
      }
    },
    //左侧列表网络请求
    ws_getCategoryListNetWrok() {
      axios
        .get(this.kHost + "paper/classifyList")
        .then((res) => {
          //成功
          if (res.data.code == 0) {
            console.log(res);
            this.categoryArray = res.data.data;
          } else {
            // uni.showToast({
            // 	title: res.statusCode != 200 ? res.statusCode : res.data.msg,
            // 	icon: 'none'
            // });
          }
        })
        .catch((res) => {
          //失败
          console.log(res);
        });
    },
    //右侧列表网络请求
    ws_getListNetWorkAction() {
      this.isEmpty = false;
      axios
        .post(this.kHost + `paper/paperGoodsList`, {
          classifyID: this.currentClassifyId,
          page: this.page,
          rows: this.pageSize,
        })
        .then((res) => {
          //成功
          if (res.data.code == 0) {
            // console.log(res);
            if (this.page == 1) {
              this.rightListArray = res.data.data || [];
              if(this.rightListArray.length == 0){
                this.isEmpty = true;
              }
            } else {
              var newArr = this.rightListArray.concat(res.data.data);
              this.rightListArray = newArr;
            }
            //当请求完毕 上拉停止
            // if (res.data.data.total == this.rightListArray.length) {
            //   this.isCanLoadMoreData = false;
            // }
            if (this.rightListArray.length%20 !=0) {
              this.isCanLoadMoreData = false;
            }
          } else {
            this.isEmpty = true;
            // uni.showToast({
            // 	title: res.statusCode != 200 ? res.statusCode : res.data.msg,
            // 	icon: 'none'
            // });
          }
          this.isRefreshData = false;
          this.isNetWork = false;
        })
        .catch((res) => {
          //失败
          console.log(res);
          this.isRefreshData = false;
          this.isNetWork = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  // height: 100vh;
  height: 100%;
  // overflow: hidden;
  position: relative;
  // 防止触摸左侧view的时候,右侧列表无法滑动/卡死现象
  touch-action: none;
  .leftContainer {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 1.5rem;
    // height: 100vh;
    // // height: 100%;
    // background-color: #e6e6e6;
    // // 防止触摸左侧view的时候,右侧列表无法滑动/卡死现象
    // touch-action: none;

    position: fixed;
    top: 0;
    left: 0;
    width: 1.5rem;
    // height: 100vh;
    height: 100%;
    background-color: #e6e6e6;
    // 防止触摸左侧view的时候,右侧列表无法滑动/卡死现象
    // touch-action: none;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }
  }

  .rightContainer {
    position: fixed;
    left: 1.5rem;
    top: 0;
    right: 0;
    height: 100%;
    overflow: scroll;
    padding-top: 0.2rem;
    background-color: #fafafa;
  }
}
</style>
